import axios from 'axios'
import envs from '@/@helpers/envs'

axios.defaults.baseURL = envs.baseURL

function updateAuthToken(token) {
  // eslint-disable-next-line
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export default updateAuthToken
