<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <div style="display:flex">
          <b-link class="brand-logo">
            <logo />
          </b-link>
        </div>
        <div
          v-if="isTokenExpired == 'true'"
          class="session-expired"
        >
          <span>{{ $t('login.session_expired') }}</span>
          <feather-icon
            class="ml-2 my-1"
            icon="AlertCircleIcon"
          />
        </div>
        <b-card-title class="mb-1">
          {{ $t('welcome_to') }} DRS Slotenmaker! 👋
        </b-card-title>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- email -->
            <b-form-group
              label-for="username"
              :label="$t('user.username')"
            >
              <validation-provider
                #default="{ errors }"
                name="Username"
                vid="username"
                rules="required"
              >
                <b-form-input
                  id="login-username"
                  v-model="userUsername"
                  name="login-username"
                  :state="errors.length > 0 ? false : null"
                  placeholder="john.example"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">{{ $t('user.password') }}</label>
                <b-link :to="{ name: 'auth-forgot-password' }">
                  <small>{{ $t('user.forgot_password') }}</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    :placeholder="$t('user.password')"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                {{ $t('user.remember_me') }}
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              class="p-0"
              block
              :disabled="invalid"
            >
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
              >
                <b-button
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  {{ $t('login.sign_in') }}
                </b-button>
              </b-overlay>
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import Logo from '@/@core/layouts/components/Logo.vue'
import updateAuthToken from '@/api/axios'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    Logo,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userUsername: '',
      password: '',
      status: '',
      // validation rules
      required,
      loading: false,
      isTokenExpired: localStorage.getItem('expiredToken'),
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.loading = true
      // eslint-disable-next-line func-names
      setTimeout(() => {
        this.loading = false
        if (this.$route.name !== 'drs-agenda') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${this.$i18n.t('toast.title.error')}`,
              icon: 'XICon',
              variant: 'danger',
              text: 'Unexpected Error',
            },
          })
        }
      }, 8000)
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login({
              username: this.userUsername,
              password: this.password,
            })
            .then(async response => {
              const { user } = response.data
              const { token } = response.data
              useJwt.setToken(token)
              useJwt.setRefreshToken(token)
              user.ability = [
                {
                  action: 'manage',
                  subject: 'all',
                },
              ]
              localStorage.setItem('userData', JSON.stringify(user))
              this.$ability.update(user.ability)

              updateAuthToken(token.slice(7))
              await this.$store.dispatch('app/fetchSystem')

              localStorage.setItem('expiredToken', false)

              this.loading = false
              this.$router
                .replace(getHomeRouteForLoggedInUser('admin'))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `${this.$i18n.t('toast.login.title')} ${response.data.user.username || ''}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      // eslint-disable-next-line prefer-template
                      text: `${this.$i18n.t('toast.login.text')} ${this.$i18n.t('common.' + `${response.data.user.role}`.toLowerCase())}.`,
                    },
                  })
                })
            })
            .catch(error => {
              this.$refs.loginForm.setErrors(error.message)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${this.$i18n.t('common.error')}`,
                  icon: 'XICon',
                  variant: 'danger',
                  text: `${error.message}`,
                },
              })
              this.loading = false
            })
        }
      })
    },
  },
}
</script>

<style scoped>
.session-expired{
  color:rgb(248, 86, 86);
  border-style: solid;
  border-width:1px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 25px;
}
  .brand-logo img {
    width: 100%;
  }
</style>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
